import { onModalToggle, toggleOverlayPortal, toggleSnackbar } from './actions';

export enum BBCommonActionTypes {
  TOGGLE_OVERLAY_PORTAL = '[BBCommon] Toggle overlay portal',
  HIDE_SNACKBAR = '[BBCommon] Hide snackbar',
  TOGGLE_SNACKBAR = '[BBCommon] Toggle snackbar',
  ON_MODAL_TOGGLE = '[BBCommon] On modal toggle',
}

export type IToggleOverlayPortalAction = ReturnType<ReturnType<typeof toggleOverlayPortal>>;
export type IToggleSnackbarAction = ReturnType<typeof toggleSnackbar>;
export type IOnModalToggleAction = ReturnType<typeof onModalToggle>;
