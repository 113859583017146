import { ReactNode } from 'react';
import { NavigationDestination } from 'lib/analyticsTypes';
import { SearchActionTypes } from 'lib/search/action-types';
import { Action, IDeps, INextAction, PushNotificationOptInAnalytics } from 'lib/types';
import { UIActionTypes } from './action-types';
import * as analytic from './analytics';
import { IBadgeState, IFullScreenImageModal, ISpecialOffersPayload } from './types';

export const toggleSearchBar =
  (barIsVisible: boolean, track: boolean = false) =>
  ({ dispatch }: IDeps): Action => {
    if (track) {
      dispatch({
        type: SearchActionTypes.TOGGLE_SEARCH_BAR_ANALYTICS,
        payload: barIsVisible,
      });
    }
    return {
      type: UIActionTypes.TOGGLE_SEARCH_BAR,
      payload: barIsVisible,
    };
  };

export const resetCopiedUrl = () => (): Action => ({
  type: UIActionTypes.RESET_COPIED_URL,
});

export const copiedUrl =
  () =>
  ({ dispatch }: IDeps): Action => {
    setTimeout(() => {
      dispatch(resetCopiedUrl());
    }, 1500);
    return {
      type: UIActionTypes.COPIED_URL,
    };
  };

export const settingsToggleLinkEmailForm = (show: boolean) => ({
  type: UIActionTypes.TOGGLE_LINK_EMAIL_FORM,
  payload: show,
});

export const settingsToggleChangeEmailForm = (show: boolean) => ({
  type: UIActionTypes.TOGGLE_SETTINGS_CHANGE_EMAIL_FORM,
  payload: show,
});

export const usedHeaderNavigation = (destination: NavigationDestination) => (deps: IDeps) => {
  const { dispatch } = deps;

  dispatch(
    analytic.getUsedNavFunction('USED_HEADER_NAVIGATION', 'Used header navigation')(destination)(
      deps,
    ),
  );

  return {
    type: 'USED_HEADER_NAVIGATION',
  };
};
export const usedToolCards = analytic.getUsedNavFunction('USED_TOOL_CARDS', 'Used tool cards');

/** @deprecated */
export const usedMenuNavigationDeprecated =
  (destination: NavigationDestination) => (deps: IDeps) => {
    const { dispatch } = deps;
    dispatch(
      analytic.getUsedNavFunction('USED_MENU_NAVIGATION', 'Used menu navigation')(destination)(
        deps,
      ),
    );

    return {
      type: 'USED_MENU_NAVIGATION',
    };
  };

/** @deprecated */
export const usedHeaderHomeButtonDeprecated = analytic.getUsedNavFunction(
  'USED_HEADER_HOME_BUTTON',
  'Used header home button',
);

export const usedHeaderHomeButton = (method: 'desktopHomeTab' | 'mobileHomeTab') => ({
  type: 'USED_HEADER_HOME_BUTTON_ANALYTICS',
  payload: {
    destination: 'home',
    event: 'Used header home button',
    method,
  },
});

export const usedArticlesTilesCarousel = analytic.getTilesCarouselFunction(
  'USED_ARTICLES_TILES_CAROUSEL',
  'Used articles tiles carousel',
);

export const goHomeBack = ({ back = true }: { back: boolean }) => ({
  type: UIActionTypes.DEBOUNCED_GO_BACK_HOME,
  payload: { back },
});

export const toggleNavMenuDrawer = (open: boolean = false) => ({
  type: UIActionTypes.TOGGLE_NAV_MENU_DRAWER,
  payload: open,
});

export const toggleSpecialOfferPopup = ({ type, offer }: ISpecialOffersPayload) => ({
  type: UIActionTypes.TOGGLE_SPECIAL_OFFER_POPUP,
  payload: { offer, type },
});

export const triggeredSpecialOfferPopUpAnalytics = (payload: ISpecialOffersPayload) => ({
  type: UIActionTypes.TRIGGERED_SPECIAL_OFFER_POP_UP_ANALYTICS,
  payload,
});

export const clickedToSeeAllAchievements = () => ({
  type: UIActionTypes.CLICKED_SEE_ALL_ACHIEVEMENTS,
});

export const toggleFullscreenImageModal = (
  toggleModal: IFullScreenImageModal = { show: false, imageUrl: '' },
) => ({
  type: UIActionTypes.TOGGLE_FULLSCREEN_IMAGE_MODAL,
  payload: toggleModal,
});

export const togglePushNotificationOptIn = (show: boolean) => ({
  type: UIActionTypes.TOGGLE_PUSH_NOTIFICATION_OPT_IN,
  payload: show,
});

export const togglePushNotificationOptInAnalytics = (payload: PushNotificationOptInAnalytics) => ({
  type: UIActionTypes.TOGGLE_PUSH_NOTIFICATION_OPT_IN_ANALYTICS,
  payload,
});

export const toggleSearchByNameModal = (show: boolean) => ({
  type: SearchActionTypes.TOGGLE_SEARCH_BY_NAME_MODAL,
  payload: show,
});

export const toggleSearchByLocationModal = (show: boolean) => ({
  type: SearchActionTypes.TOGGLE_SEARCH_BY_LOCATION_MODAL,
  payload: show,
});

export const coupleClickedInboxMenu = () => ({
  type: UIActionTypes.COUPLE_CLICKED_INBOX_MENU_ANALYTICS,
});

export const showMobileNav = (payload: boolean) => ({
  type: UIActionTypes.SHOW_MOBILE_NAV,
  payload,
});

export interface IAchievement {
  icon: ReactNode;
  title: string;
  description: string;
}

export const setAchievementUnlocked = (achievement: IBadgeState) => ({
  type: UIActionTypes.SET_ACHIEVEMENT_UNLOCKED,
  payload: {
    achievement,
  },
});

export const setNextAction = (nextAction: INextAction) => ({
  type: UIActionTypes.SET_NEXT_ACTION,
  payload: {
    nextAction,
  },
});

export const showNextAction = () => ({
  type: UIActionTypes.SHOW_NEXT_ACTION,
});

export const hideNextAction = () => ({
  type: UIActionTypes.HIDE_NEXT_ACTION,
});

export const completeNextAction = () => ({
  type: UIActionTypes.COMPLETE_NEXT_ACTION,
});

export const identifyVenueRecommendedSuppliers = (
  venueRecommendedSuppliers: any,
  name?: string,
) => ({
  type: UIActionTypes.IDENTIFY_VENUE_RECOMMENDED_SUPPLIERS,
  payload: {
    venueRecommendedSuppliers,
    name,
  },
});

export function hideAchievement(payload: { id: string }) {
  return {
    type: UIActionTypes.HIDE_ACHIEVEMENT,
    payload,
  };
}

export const fetchBadgesSuccess = (payload: Record<string, IBadgeState>) => ({
  type: UIActionTypes.FETCH_BADGES_SUCCESS,
  payload,
});

export const triggerAchievementPopupAnalytics = (payload: { achievement: IBadgeState }) => ({
  type: UIActionTypes.TRIGGERED_ACHIEVEMENT_BADGE_POPUP,
  payload,
});

export enum AchievementModalInteractionTypes {
  DISCOVER_WHATS_NEXT = "Discover what's next",
  NOTICED = 'Noticed and closed achievement badge',
  CLOSED = 'closed',
}
export const interactedWithAchievementModal = (payload: {
  achievement: IBadgeState;
  interactionType: AchievementModalInteractionTypes;
}) => ({
  type: UIActionTypes.INTERACTED_WITH_ACHIEVEMENT_BADGE_POPUP,
  payload,
});

export const toggleLostLeadModal = (payload: { show: boolean; supplierId: string | null }) => ({
  type: UIActionTypes.TOGGLE_NOT_INTERESTED_IN_SUPPLIER_MODAL,
  payload,
});

export const toggleRatingModal = () => ({
  type: UIActionTypes.TOGGLE_RATING_MODAL,
});

export const toggleCalendlyModal = () => ({
  type: UIActionTypes.TOGGLE_CALENDLY_MODAL,
});
