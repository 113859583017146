import type { IOffer } from '@bridebook/models/source/models/Suppliers/Offers.types';
import { getSupplierUrl } from '@bridebook/toolbox';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { createWeddingLevelIdentify, getLocalisation, variationTest } from 'lib/analytics-utils';
import { getIsCollaborator } from 'lib/analytics-utils/get-is-collaborator';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { env } from 'lib/env';
import { getI18n } from 'lib/i18n/getI18n';
import { Action, IApplicationState, IDeps, IElasticSupplier } from 'lib/types';
import { imgixBaseURL } from 'lib/utils';
import { weddingDetailsPropertiesGeneric } from 'lib/weddings/analytics';
import {
  CarouselPropArg,
  NavPropArg,
  NavigationDestination,
  TilesCarouselAction,
  TilesCarouselLocation,
} from '../analyticsTypes';
import { OfferTypes } from '../offers/constants';
import { convertLateDatesToTimestamps } from '../offers/utils';
import { UIActionTypes } from './action-types';
import { ISpecialOffersPayload } from './types';

function getNavigationMethod(
  getState: () => IApplicationState,
  destination: NavigationDestination,
) {
  const {
    app: {
      device: { isMobile },
    },
  } = getState();

  switch (destination) {
    case 'home':
      return isMobile ? 'mobileHomeTab' : 'desktopHomeTab';
    case 'messages':
      return isMobile ? 'mobileMessage' : 'desktopMessage';
    case 'planning':
      return isMobile ? 'mobilePlanningTab' : 'desktopPlanningTab';
    case 'suppliers':
      return isMobile ? 'mobileSupplierTab' : 'desktopSupplierTab';
    case 'shortlist':
      return isMobile ? 'mobileFavouritesTab' : 'desktopFavouritesTab';
  }

  return isMobile ? 'mobileHeaderFull' : 'desktopHeaderFull';
}

const navigationPropertiesGeneric = ({ method, destination }: NavPropArg) => ({
  navigationDestination: destination,
  navigationMethod: method,
});

export const tilesCarouselPropertiesGeneric = ({ action, location }: CarouselPropArg) => ({
  tilesCarouselAction: action,
  tilesCarouselLocation: location,
});

export function getUsedNavFunction(type: string, event: string) {
  return (destination: NavigationDestination) =>
    ({ getState }: IDeps) => {
      const method = getNavigationMethod(getState, destination);
      return {
        type: `${type}_ANALYTICS`,
        payload: { event, method, destination },
      };
    };
}

export function getTilesCarouselFunction(type: string, event: string) {
  return (action: TilesCarouselAction, location: TilesCarouselLocation = 'home') => ({
    type: `${type}_ANALYTICS`,
    payload: { event, action, location },
  });
}

interface IClickedAnalyticsPayload {
  category?: string;
  cta?: string;
  clickedSection?: string;
  clickedLocation?: string;
  mobileApp?: boolean;
  supplierId?: string;
}

export const clickedAnalytics =
  (payload: IClickedAnalyticsPayload) =>
  ({ getState }: IDeps) => {
    const state = getState();
    return {
      type: UIActionTypes.CLICKED,
      payload: {
        previousPath: state.app.previousPath,
        ...payload,
      },
    };
  };

export const specialOfferPropertiesGeneric = ({ type, offer }: ISpecialOffersPayload) => {
  const { title, details, expiration } = offer || ({} as IOffer);
  const specialOfferDates =
    type === OfferTypes.lateAvailability && offer?.dates
      ? convertLateDatesToTimestamps(offer?.dates)
      : undefined;

  return {
    specialOfferType: type,
    specialOfferTitle: title,
    specialOfferDetails: details,
    specialOfferDeadline: expiration,
    specialOfferDates,
  };
};

export default function uiAnalytics(
  { type, payload }: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { track } = bridebookAnalytics.getMethods('Navigation');

  switch (type) {
    case 'USED_HEADER_NAVIGATION_ANALYTICS':
    case 'USED_MENU_NAVIGATION_ANALYTICS':
    case 'USED_TOOL_CARDS_ANALYTICS':
    case 'USED_HEADER_HOME_BUTTON_ANALYTICS': {
      const { event, method, destination } = payload;
      const state = getState();
      const collaborator = getIsCollaborator(state);
      const previousPath = state.app.previousPath;
      const isMenu = type === 'USED_MENU_NAVIGATION_ANALYTICS';
      const userEmail = state.users.user?.contacts?.email;
      const navigationDestination =
        isMenu && destination === 'booked suppliers' ? 'booked' : undefined;
      // find first non-generic value in path to determine current user's general location
      const navigationSource = state.app.lastPath.split('/').find((s) => s && !s.includes('['));

      track({
        event,
        profileType: 'user',
        collaborator,
        previousPath,
        userEmail,
        ...navigationPropertiesGeneric({ method, destination }),
        ...(navigationDestination &&
          type !== 'USED_TOOL_CARDS_ANALYTICS' && { navigationDestination }),
        ...(navigationSource &&
          type === 'USED_HEADER_NAVIGATION_ANALYTICS' && { navigationSource }),
      });
      break;
    }
    case 'USED_RECENTLY_VIEWED_TILES_CAROUSEL_ANALYTICS':
    case 'USED_ARTICLES_TILES_CAROUSEL_ANALYTICS': {
      const { event, action, location } = payload;
      track({
        event,
        ...tilesCarouselPropertiesGeneric({ action, location }),
      });
      break;
    }
    // https://docs.google.com/spreadsheets/d/1sXMNsU7ThA7Yjwgfxg-JqXxGeziRneNnjLyxrdbvV_c/edit#gid=346801395
    // Miscellaneous tracking events v28 2017 06 15
    case 'USER_CLICKED_OUTBOUND_LINK_ANALYTICS': {
      const { url, type } = payload;
      const href = variationTest(getState);
      track({
        event: 'User clicked outbound link',
        category: 'Outbound links',
        clickedOutboundLinkType: type,
        clickedOutboundLinkURL: url,
        url: href,
      });
      break;
    }
    case 'USED_COUNTRY_MAP_ON_DIRECTORY_LANDING_PAGE_ANALYTICS': {
      const { country } = payload;
      track({
        event: 'Used country map on directory landing page',
        category: 'Landing Page',
        usedCountryMapClickedCountry: country,
      });
      break;
    }
    case 'CLICKED_TILE_ON_TOOLS_LANDING_PAGE_ANALYTICS': {
      const { clickedTileType } = payload;
      track({
        event: 'Clicked tile on tools landing page',
        category: 'Landing pages',
        clickedTileType,
      });
      break;
    }

    case UIActionTypes.TRIGGERED_SPECIAL_OFFER_POP_UP_ANALYTICS:
      track({
        event: 'Triggered special offer pop up',
        ...specialOfferPropertiesGeneric(payload as ISpecialOffersPayload),
      });
      break;

    case UIActionTypes.COUPLE_CLICKED_INBOX_MENU_ANALYTICS: {
      const state = getState();
      const userEmail = state.users.user?.contacts?.email;
      track({
        event: 'Couple clicked Messages item in burger menu',
        userEmail,
        ...weddingDetailsPropertiesGeneric(state),
      });
      break;
    }

    case UIActionTypes.TRIGGERED_ACHIEVEMENT_BADGE_POPUP: {
      const state = getState();
      const i18n = getI18n();
      const translate = i18n.t.bind(i18n); // Used to avoid i18next-parser parsing
      const { title, descShort, icon } = payload.achievement;
      const userEmail = state.users.user?.contacts?.email;

      track({
        event: 'Triggered achievement badge popup',
        achievementName: translate(`achievements:${title}`),
        achievementDescription: translate(`achievements:${descShort}`),
        achievementIcon: icon,
        userEmail,
        ...getLocalisation(state),
      });
      break;
    }

    case UIActionTypes.CLICKED_SEE_ALL_ACHIEVEMENTS: {
      const state = getState();
      const {
        app: { previousPath },
        users: { user },
        weddings: { profile },
      } = state;
      const userEmail = user?.contacts?.email;
      const weddingId = profile?.id;
      const collaborator = getIsCollaborator(state);

      track({
        event: 'Clicked to see all planning achievements',
        category: 'navigation',
        previousPath,
        collaborator,
        profileType: 'user',
        userEmail,
        weddingId,
        ...getLocalisation(state),
      });
      break;
    }

    case UIActionTypes.INTERACTED_WITH_ACHIEVEMENT_BADGE_POPUP: {
      const state = getState();
      const { interactionType } = payload;
      const i18n = getI18n();
      const translate = i18n.t.bind(i18n); // Used to avoid i18next-parser parsing

      const { title, descShort, icon } = payload.achievement;
      const userEmail = state.users.user?.contacts?.email;
      track({
        event: 'Interacted with achievement badge popup',
        achievementName: translate(`achievements:${title}`),
        achievementDescription: translate(`achievements:${descShort}`),
        achievementIcon: icon,
        interactionType,
        userEmail,
        ...getLocalisation(state),
      });
      break;
    }

    case UIActionTypes.IDENTIFY_VENUE_RECOMMENDED_SUPPLIERS:
      {
        const state = getState();
        const { venueRecommendedSuppliers, name } = payload;
        const { identifyWithTrack: identify } = bridebookAnalytics.getMethods('All');
        const eventName =
          name === 'recommendedLocalSuppliers'
            ? 'Recommended local suppliers'
            : 'Venue recommended suppliers';
        const mappedList = venueRecommendedSuppliers.map((supplier: IElasticSupplier) => ({
          supplierId: supplier.id,
          country: supplier.country,
          county: supplier.county,
          description: supplier.description,
          name: supplier.name,
          thumbnail: `${imgixBaseURL}/${supplier.thumbnail}`,
          minPrice: supplier.price || supplier.supplierPriceMin,
          category: supplier.type,
          supplierURL: env.COUPLESIDE_URL + getSupplierUrl(supplier),
        }));
        try {
          identify(
            {
              isAnonymous: false,
              ...(name ? { [name]: mappedList } : { venueRecommendedSuppliers: mappedList }),
            },
            {
              event: eventName,
              ...(name ? { [name]: mappedList } : { venueRecommendedSuppliers: mappedList }),
            },
            { userId: state.users.user?.id as string },
          );
        } catch (e) {
          SentryMinimal().captureException(e, {
            tags: {
              source: name || 'venueRecommendedSuppliers',
            },
          });
        }
        const serverIdentify = createWeddingLevelIdentify<{ pbStatus: boolean }>(getState);
        serverIdentify({ pbStatus: true });
      }

      break;
    case UIActionTypes.CLICKED: {
      const state = getState();
      const collaborator = getIsCollaborator(state);
      track({
        event: 'Clicked',
        ...payload,
        collaborator,
      });
      break;
    }
    // eslint-disable-next-line no-fallthrough
    default:
      break;
  }
}
